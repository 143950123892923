export default [
    {
        key: 'displayText',
        label: 'field.display_text',
        type: 'text',
        cols: 4,
    },
    {
        key: 'price',
        label: 'field.sortPurchasePrice',
        type: 'singleSelect',
        cols: 4,
        options: [
        {
            name: 'field.mostPay',
            id: 'desc'
        },
        {
            name: 'field.leastPay',
            id: 'asc'
        }
        ]
    },
    {
        key: 'popular',
        label: 'field.sortPurchaseTime',
        type: 'singleSelect',
        cols: 4,
        options: [
        {
            name: 'field.mostPay',
            id: 'desc'
        },
        {
            name: 'field.leastPay',
            id: 'asc'
        }
        ]
    },
    {
        key: 'isEnable',
        label: 'field.status',
        rules: '',
        type: 'radio',
        cast: 'boolean',
        options: [
        { text: 'status.active', value: '1' },
        { text: 'status.inactive', value: '2' },
        ],
    },
]
