export default [
  {
    key: 'increasement',
    sortable: false,
    sortField: '',
    label: '#',
    thClass: 'w-50px',
  },
  {
    key: 'image',
    sortable: false,
    sortField: '',
    label: 'field.image',
    stickyColumn: true,
    variant: 'light',
  },
  {
    key: 'amount',
    sortable: true,
    sortField: 'amount',
    label: 'field.amount_diamond',
    stickyColumn: true,
    variant: 'light',
  },
  {
    key: 'price',
    sortable: true,
    sortField: 'price',
    label: 'field.price',
    stickyColumn: true,
    variant: 'light',
  },
  {
    key: 'wholeSalePrice',
    sortable: true,
    sortField: 'wholeSalePrice',
    label: 'field.wholeSalePrice',
    stickyColumn: true,
    variant: 'light',
  },
  {
    key: 'displayText',
    sortable: false,
    sortField: 'display_text',
    label: 'field.display_text',
    stickyColumn: true,
    variant: 'light',
  },
  {
    key: 'totalPrice',
    sortable: false,
    sortField: 'totalPrice',
    label: 'field.totalPurchasePrice',
    stickyColumn: true,
    variant: 'light',
  },
  {
    key: 'transactionCount',
    sortable: false,
    sortField: 'transactionCount',
    label: 'field.totalPurchaseTime',
    stickyColumn: true,
    variant: 'light',
  },
  {
    key: 'isEnable',
    sortable: false,
    sortField: '',
    label: 'field.status',
  },

  {
    key: 'actions',
    label: 'general.action',
  },
]
